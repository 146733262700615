import Badge from 'components/badge';
import { useCommon } from 'contexts/common';
import { Product } from 'interfaces/product';
import { CollectionProduct } from 'models/collection/collection';
import { StaticPDPProduct } from 'modules/product-details/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import hasPersonalizedDiscount from 'utils/has-personalized-discount';

interface AccentuateBadgesProps {
  className?: string;
  color?: string;
  product?: CollectionProduct | StaticPDPProduct | Product;
  subTitle?: string;
  title?: string;
}

const AccentuateBadges: React.FC<AccentuateBadgesProps> = ({
  className,
  color,
  product,
  subTitle,
  title,
}) => {
  const { globalDiscount } = useCommon();
  const hasDiscount = product
    ? hasPersonalizedDiscount(product, globalDiscount)
    : false;

  const badges: Array<React.ReactNode> = [];
  if (title || subTitle) {
    badges.push(
      <Badge
        className={className}
        key={title}
        type={
          color === 'Red-Sale'
            ? 'Discount'
            : color === 'Generic-Beige'
              ? 'General'
              : 'Limited'
        }
      >
        {title} {subTitle}
      </Badge>
    );
  }

  if (hasDiscount) {
    badges.push(
      <Badge className={className} type="Discount" key={'discount'}>
        <FormattedMessage
          id={'cdp:tile:badge:save'}
          values={{
            amount: globalDiscount.discount?.amountInPercent,
          }}
        />
      </Badge>
    );
  }

  return (
    <>
      {badges.map((badge, index) => {
        return <React.Fragment key={index}>{badge}</React.Fragment>;
      })}
    </>
  );
};

export default AccentuateBadges;
