import cn from 'classnames';

interface IProductCardSkeletonProps {
  isSlider?: boolean;
}

/**
 * ProductCardSkeleton Component
 */
const ProductCardSkeleton: React.FC<IProductCardSkeletonProps> = ({
  isSlider,
}) => (
  <div
    className={cn(
      isSlider
        ? 'h-[312px] flex-col items-start lg:h-[356px]'
        : 'items-center lg:h-[466px]',
      'flex h-[172px] gap-2 rounded-sm bg-other-white py-3 pl-2 pr-3 shadow-sm lg:flex-col lg:items-start'
    )}
  >
    <div
      className={cn(
        isSlider ? 'w-full' : 'w-2/5',
        'h-32 animate-pulse rounded-lg bg-grey-100 px-12 py-8 lg:h-46 lg:w-full'
      )}
    />
    <div
      className={cn(
        isSlider ? 'w-full' : 'w-3/5',
        'flex h-32 flex-col justify-between gap-2.5 lg:w-full'
      )}
    >
      <div className="h-3.5 w-3/4 shrink-0 animate-pulse rounded-2xl bg-grey-100" />
      <div className="h-3.5 w-1/2 shrink-0 animate-pulse rounded-2xl bg-grey-100" />
      <div className="h-2.5 w-4/5 shrink-0 animate-pulse rounded-2xl bg-grey-100 lg:h-4" />
      <div className="hidden h-4 w-4/6 shrink-0 animate-pulse rounded-2xl bg-grey-100 lg:block" />
      <div
        className={cn(
          isSlider ? 'h-3' : 'hidden h-4',
          'w-full shrink-0 animate-pulse rounded-2xl bg-grey-100 lg:block'
        )}
      />

      <div
        className={cn(
          isSlider ? 'h-3' : 'h-2.5',
          'w-4/5 shrink-0 rounded-2xl bg-grey-100 lg:hidden lg:h-4'
        )}
      />
      <div
        className={cn(
          isSlider ? 'h-3' : 'h-2.5',
          'w-2/3 shrink-0 rounded-2xl bg-grey-100 lg:hidden lg:h-4 lg:w-3/5'
        )}
      />

      <div className="flex w-1/4 shrink-0 gap-3 lg:w-1/4">
        <div className="h-2.5 w-full shrink-0 rounded-2xl bg-grey-100 lg:h-4" />
        <div className="h-2.5 w-full shrink-0 rounded-2xl bg-grey-100 lg:h-4" />
      </div>
    </div>
  </div>
);

export default ProductCardSkeleton;
