import cn from 'classnames';
import { useTrackingContext } from 'contexts/tracking';
import { CollectionProduct } from 'models/collection/collection';
import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';
import { selectElement } from 'tracking';
import { Gtm, gtm } from 'tracking/gtm';
import Content from './content';

export const CARD_TYPES = {
  SLIDER: 'slider',
  SLIDER_LARGE: 'slider large',
  REGULAR: 'regular',
} as const;

export type CARD_TYPES = (typeof CARD_TYPES)[keyof typeof CARD_TYPES];

interface PropTypes
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /** Collection Title for tracking */
  collectionTitle: string;
  isBestSeller?: boolean;
  position: number;
  product: CollectionProduct;
  shouldShowBlackRibbon?: boolean;
  showSubscriptionPrice?: boolean;
  starSize?: 'small' | 'medium';
  trackingElementName?: string | string[];
  type?: CARD_TYPES;
  onTileClick?: () => void;
  /** Url query to be added  */
  additionalQuery?: Record<string, string>;
}

/**
 * Product Card. Wrapper for Product Card Content.
 * Includes tracking and navigation.
 */
const ProductCard: React.FC<PropTypes> = ({
  className,
  collectionTitle,
  isBestSeller,
  position,
  product,
  shouldShowBlackRibbon,
  showSubscriptionPrice = false,
  starSize,
  trackingElementName,
  type = CARD_TYPES.REGULAR,
  onTileClick,
  additionalQuery,
}) => {
  const { id: productId, title: productTitle, handle } = product;
  const { setTrackedList } = useTrackingContext();

  const onGoToProduct = useCallback(() => {
    trackingElementName &&
      gtm(
        selectElement({
          element: [
            ...(Array.isArray(trackingElementName)
              ? trackingElementName
              : [trackingElementName]),
            productTitle,
          ],
        })
      );
    const { tags, productType } = product;
    gtm({
      group: Gtm.GroupName.Product,
      name: Gtm.ProductEventName.Click,
      data: {
        product: {
          productTitle,
          productId,
          productType,
          tags: tags.join(','),
          position,
          list: collectionTitle || '',
        },
      },
    });
    setTrackedList(productId, collectionTitle || '');
  }, [
    productId,
    productTitle,
    collectionTitle,
    position,
    product,
    trackingElementName,
    setTrackedList,
  ]);

  const promotedVariant = useMemo(
    () =>
      product.variants.find(
        (variant) => variant.id === product.promoteVariantId
      ),
    [product]
  );

  const link = useMemo(() => {
    let queryObj = {};
    if (product.promoteVariantId && promotedVariant?.availableForSale) {
      queryObj = {
        ...queryObj,
        variant: product.promoteVariantId,
      };
    }
    if (additionalQuery) {
      queryObj = {
        ...queryObj,
        ...additionalQuery,
      };
    }
    const searchParam = new URLSearchParams(queryObj);

    return `/products/${handle}${searchParam.size > 0 ? '?' + searchParam.toString() : ''}`;
  }, [
    additionalQuery,
    handle,
    product.promoteVariantId,
    promotedVariant?.availableForSale,
  ]);

  const content = (
    <Content
      isBestSeller={isBestSeller}
      product={product}
      showSubscriptionPrice={showSubscriptionPrice}
      starSize={starSize}
      type={type}
      shouldShowBlackRibbon={shouldShowBlackRibbon}
    />
  );

  return (<>
    {onTileClick ? (
      <button
        className={cn(
          'focus-default flex h-full w-full decoration-transparent',
          className
        )}
        onClick={onTileClick}
      >
        {content}
      </button>
    ) : (
      <Link
        href={link}
        passHref
        className={cn(
          'focus-default flex h-full w-full decoration-transparent',
          className
        )}
        onClick={onGoToProduct}>

        {content}

      </Link>
    )}
  </>);
};

export default ProductCard;
