import cn from 'classnames';
import Badge from 'components/badge';
import JudgeMePreviewBadge from 'components/judge-me-preview-badge';
import Price from 'components/price';
import { useConfig } from 'config';
import { useCommon } from 'contexts/common';
import { useSegmentationContext } from 'contexts/segmentation';
import useIsClient from 'hooks/common/use-is-client';
import useMobile from 'hooks/common/use-mobile';
import useProduct from 'hooks/product/use-product';
import { CollectionProduct } from 'models/collection/collection';
import Image from 'next/legacy/image';
import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import hasPersonalizedDiscount from 'utils/has-personalized-discount';
import { generalImageLoader } from 'utils/image-loader';
import { minusPercent } from 'utils/product';
import { CARD_TYPES } from '.';
import AccentuateBadges from './accentuate-badges';

interface Props {
  isBestSeller?: boolean;
  product: CollectionProduct;
  shouldShowBlackRibbon?: boolean;
  showSubscriptionPrice: boolean;
  starSize?: 'small' | 'medium';
  type: (typeof CARD_TYPES)[keyof typeof CARD_TYPES];
}

/**
 * Product Card Content. Displays product image, title, rating, price and subscription price.
 * @TODO : After migration to PDEX stacks, logics of discount calculations will be obsolete
 */
const Content: React.FC<Props> = ({
  product,
  shouldShowBlackRibbon,
  showSubscriptionPrice,
  starSize,
  type,
  isBestSeller,
}) => {
  const { isClient } = useIsClient();
  const isMobile = useMobile();
  const { globalDiscount } = useCommon();
  const { subscriptionDiscount } = useConfig();
  const hasDiscount = hasPersonalizedDiscount(product, globalDiscount, true);
  const { id: productId, availableForSale } = product;

  const { comparePrice, price } = useProduct(product, {
    isSubscriptionSelected: showSubscriptionPrice,
  });

  const { shouldOmitPersonalization, multipleSegmentation } =
    useSegmentationContext();

  const subTitle = useMemo(() => {
    if (shouldOmitPersonalization) {
      return product.getProductSubtitle();
    }
    return product.getProductSubtitle({
      segmentation: multipleSegmentation?.lastSegmentation,
    });
  }, [
    multipleSegmentation?.lastSegmentation,
    product,
    shouldOmitPersonalization,
  ]);

  const shouldShowBadgesSection =
    type !== CARD_TYPES.SLIDER &&
    isClient &&
    (isBestSeller || product.getRibbon().ribbonText || shouldShowBlackRibbon);

  const displayTitle = useMemo(() => {
    return product.getProductTitle();
  }, [product]);

  const ribbon = useMemo(() => product.getRibbon(), [product]);

  // QA Temporary: Used during PDEX transition
  const titleSource = product.getProductTitle() ? 'accentuate' : 'product';

  const subscriptionValue = hasDiscount
    ? globalDiscount.discount?.firstSubscriptionOrderDiscount
    : subscriptionDiscount;

  const firstCheapestAvailableSubsVariant = useMemo(() => {
    // if smart collection item is dealt here, we show price
    if (product.getIsSmartCollectionItem()) {
      return +product.variants[0].priceV2.amount;
    }

    const _firstCheapestAvailableSubsVariant =
      product.getFirstCheapestAvailableSubsVariant();

    return subscriptionValue && _firstCheapestAvailableSubsVariant
      ? minusPercent(
          +_firstCheapestAvailableSubsVariant.priceV2.amount,
          subscriptionValue
        )
      : null;
  }, [product, subscriptionValue]);

  const badgeSection = useMemo(() => {
    return (
      <>
        {shouldShowBlackRibbon ? (
          <Badge
            type="General"
            data-test="black-deals-badge"
            className="bg-other-black! text-text-dark-bg-contrast-white!"
          >
            BLACK DEAL
          </Badge>
        ) : (
          <>
            {isBestSeller && (
              <Badge type="Bestseller" data-test="personalized-ribbon-text">
                <FormattedMessage id="common:bestseller" />
              </Badge>
            )}
            <AccentuateBadges
              title={ribbon.ribbonText}
              subTitle={ribbon.ribbonText2}
              color={ribbon.ribbonColor}
              product={product}
              data-test="accentuate-badges"
            />
          </>
        )}
      </>
    );
  }, [
    isBestSeller,
    product,
    ribbon.ribbonColor,
    ribbon.ribbonText,
    ribbon.ribbonText2,
    shouldShowBlackRibbon,
  ]);

  const renderWeight = useCallback(
    (weight: string, index: number, arr: string[]) => (
      <React.Fragment key={index}>
        {weight}
        {index < arr.length - 1 ? ' / ' : ''}
      </React.Fragment>
    ),
    []
  );

  return (
    <div
      className={cn(
        'group relative h-full min-h-[172px] w-full rounded-lg bg-other-white pl-2 pr-3 shadow-xs lg:px-2 lg:pb-4 lg:pt-2',
        type === CARD_TYPES.SLIDER && 'px-2 py-3 lg:px-4 lg:pb-4 lg:pt-2'
      )}
      data-test="product-card"
    >
      <div
        className={cn(
          'flex h-full gap-2 lg:flex-col lg:gap-2.5',
          shouldShowBadgesSection ? 'pt-7 lg:pt-0' : 'pt-2 lg:pt-0',
          type === CARD_TYPES.SLIDER || type === CARD_TYPES.SLIDER_LARGE
            ? 'flex-col pb-0'
            : 'pb-3'
        )}
        data-test="product-card-content"
      >
        {shouldShowBadgesSection && type === CARD_TYPES.REGULAR && (
          <div
            className="absolute left-1.5 top-1.5 z-10 flex w-full gap-1.5 lg:left-2 lg:top-2"
            data-test="badges-section"
          >
            {badgeSection}
          </div>
        )}
        {/* Product Image */}
        <div
          className={cn(
            'flex items-center justify-center',
            !availableForSale && 'opacity-70',
            type === CARD_TYPES.REGULAR &&
              'min-h-[130px] min-w-[130px] lg:min-h-[223px] '
          )}
          data-test="product-image-container"
        >
          <div
            className={cn(
              'relative flex w-full items-center justify-center',
              type === CARD_TYPES.SLIDER && 'h-full'
            )}
            data-test="product-image-wrapper"
          >
            {!availableForSale && (
              <Badge
                className="absolute left-[50%] top-[50%] z-10 flex h-10! w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-sm bg-grey-50 text-sm text-text-primary"
                data-test="out-of-stock-badge"
              >
                <FormattedMessage id="pdp:buy-panel:product-out-stock" />
              </Badge>
            )}
            {product.image.src && (
              <div className={cn('')} data-test="product-image">
                <div
                  className={cn(
                    type === CARD_TYPES.REGULAR &&
                      'transition-all duration-300 ease-in-out lg:group-hover:h-[200px] lg:group-hover:w-[200px]',
                    type === CARD_TYPES.SLIDER_LARGE
                      ? 'h-[270px] w-[270px] lg:h-[272px] lg:w-[272px]'
                      : 'h-[130px] w-[130px] lg:h-[188px] lg:w-[188px]'
                  )}
                  data-test="image-container"
                >
                  <Image
                    data-test="product-image-element"
                    loader={generalImageLoader}
                    src={product.image.src}
                    alt={product.image.alt || product.title || ''}
                    width={800}
                    height={800}
                    sizes="(min-width: 1024px) 33vw, (min-width: 769px) 50vw, 50vw"
                    className="h-auto"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Product Data */}
        <div
          className={cn(
            'flex flex-col items-start gap-1 bg-other-white text-left align-top',
            type === CARD_TYPES.SLIDER && 'p-0'
          )}
          data-test="product-data"
        >
          <section
            className="line-clamp-2 w-full pt-2 font-mindset text-lg leading-6 tracking-wide text-text-light-bg-primary lg:pt-0"
            data-test="product-title"
            data-test-pdp-title={product.getProductTitle()}
            data-test-title-source={titleSource}
          >
            {displayTitle}
          </section>
          {/* Product Rating */}
          <JudgeMePreviewBadge
            productId={productId}
            numberDisplayType="number"
            starSize={starSize}
            data-test="product-rating"
            hideNumberOfReviews={type === CARD_TYPES.SLIDER && isMobile}
          />
          {/* Product Subtitle */}
          {isClient && subTitle && (
            <div
              data-test="product-subtitle"
              className={cn(
                'text-sm leading-[18px] text-text-secondary',
                isMobile ? 'line-clamp-4' : 'line-clamp-2'
              )}
            >
              {subTitle}
            </div>
          )}
          <div
            className={cn(
              'font-black',
              comparePrice
                ? 'text-text-light-bg-discounted-price'
                : 'text-text-light-bg-primary'
            )}
            data-test="product-price"
          >
            {product.getShouldShowFromPrice() ? (
              <span data-test="price-from">
                <FormattedMessage id="cdp:general:price_from" />
                &nbsp;
              </span>
            ) : null}
            <span>
              <Price value={firstCheapestAvailableSubsVariant ?? price} />
            </span>
            {comparePrice && (
              <span
                className="ml-2 text-sm font-normal text-text-secondary line-through"
                data-test="compare-price"
              >
                <Price value={comparePrice} />
              </span>
            )}{' '}
          </div>
        </div>

        {type === CARD_TYPES.REGULAR && (
          <p
            className="hidden text-left text-text-secondary lg:line-clamp-2"
            data-test="product-weights"
          >
            {product.getWeights().map(renderWeight)}
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(Content);
