import cn from 'classnames';

interface Props {
  className?: string;
  rounded?: boolean;
  type?:
    | 'General'
    | 'Limited'
    | 'Discount'
    | 'Bestseller'
    | 'Generic-Beige'
    | 'Red-Sale'
    | string;
}

/**
 * Badge component
 */
const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  rounded,
  type,
}) => {
  let colorClassName = '';

  switch (type) {
    case 'Red-Sale':
    case 'Discount':
      colorClassName =
        'bg-badge-discount font-medium text-text-dark-bg-contrast-white';
      break;
    case 'Bestseller':
      colorClassName = 'bg-badge-bestseller text-primary-main';
      break;
    case 'General':
    case 'Generic-Beige':
      colorClassName = 'bg-badge-generic text-text-primary';
      break;
    default:
      colorClassName = 'bg-badge-new text-primary-main';
      break;
  }

  return (
    <div
      className={cn(
        'h-auto shrink-0 items-center justify-center px-1.5 py-0.5 text-center text-sm ',
        colorClassName,
        rounded ? 'rounded-full' : 'rounded-sm',
        className
      )}
    >
      <span data-test="ribbon" className="block">
        {children}
      </span>
    </div>
  );
};
export default Badge;
